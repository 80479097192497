<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useLocalization } from '/~/composables/localization'
import TermsConditions from '/~/views/terms/terms-conditions.vue'

const { translate } = useLocalization()
</script>

<template>
  <base-aside-page :title="`Terms ${translate('common.and')} Conditions`">
    <terms-conditions class="-m-5 bg-eonx-neutral-50 p-5" />
  </base-aside-page>
</template>
